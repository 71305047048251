<script setup lang="ts">
import { hasDemoBeenSeen } from '~/composables/driver'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
})

const {
  user,
  isAdmin,
  isProducer,
} = storeToRefs(useAuthStore())
const { acceptCGU } = useUsersStore()
const { startDriver } = useDriver()

function close() {
  const dialog = document.getElementById(props.id) as HTMLDialogElement
  dialog.close()
}

async function acceptCondition() {
  try {
    await acceptCGU()
    if (!hasDemoBeenSeen.value) {
      startDriver()
    }
    close()
  }
  catch (error) {
    addToast(t('toast.error.cannot_accept_cgu'), { type: 'error', error: error as Error })
    console.error(error)
  }
}

const { t } = useI18n()

onMounted(() => {
  if (!isAdmin.value && isProducer.value && !user.value.user.acceptedTermsAt) {
    const dialog = document.getElementById(props.id) as HTMLDialogElement
    dialog.showModal()
  }
})
</script>

<template>
  <WModal :id="id">
    <template #title>
      <div class="flex items-center gap-2 mb-2">
        {{ t("global.cgu") }}
      </div>
    </template>
    <template #default>
      <div>
        <nav class="mb-12 p-4 rounded-lg shadow-sm">
          <h2 class="text-xl font-bold mb-4">
            Table des matières
          </h2>
          <ul class="space-y-2">
            <li><a href="#article-1" class="text-base-content underline">Article 1 - Définitions</a></li>
            <li><a href="#article-2" class="text-base-content underline">Article 2 - Conditions de mise à disposition des services WasteX</a></li>
            <li><a href="#article-3" class="text-base-content underline">Article 3 - La passation de commande et son exécution</a></li>
            <li><a href="#article-4" class="text-base-content underline">Article 4 - Maintenance et évolution de la plateforme</a></li>
            <li><a href="#article-5" class="text-base-content underline">Article 5 - Obligations du client</a></li>
            <li><a href="#article-6" class="text-base-content underline">Article 6 - Propriété intellectuelle</a></li>
            <li><a href="#article-7" class="text-base-content underline">Article 7 - Protection des données personnelles</a></li>
            <li><a href="#article-8" class="text-base-content underline">Article 8 - Responsabilité</a></li>
            <li><a href="#article-9" class="text-base-content underline">Article 9 - Confidentialité</a></li>
            <li><a href="#article-10" class="text-base-content underline">Article 10 - Loi applicable et juridiction compétente</a></li>
          </ul>
        </nav>

        <main class="space-y-12">
          <!-- Article 1 -->
          <section id="article-1" class="p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 1 - DEFINITIONS
            </h2>
            <div class="space-y-4">
              <p><span class="font-semibold">Anomalie :</span> désigne tout dysfonctionnement ou non-conformité reproductible de tout ou partie de la Plateforme par rapport à ses fonctionnalités, documentée par le Client, qui empêche le fonctionnement normal de tout ou partie de la Plateforme, indépendamment d'une mauvaise utilisation.</p>

              <p><span class="font-semibold">Anomalie bloquante :</span> désigne toute anomalie empêchant l'utilisation de certaines fonctionnalités essentielles de la Plateforme.</p>

              <p><span class="font-semibold">Anomalie majeure :</span> désigne toute anomalie qui génère une dégradation importante d'au moins une fonction de la Plateforme ou de ses performances.</p>

              <p><span class="font-semibold">Anomalie mineure :</span> désigne toute anomalie empêchant l'utilisation de certaines fonctionnalités non essentielles de la Plateforme, et qui n'est ni bloquante ni majeure.</p>

              <p><span class="font-semibold">Client :</span> désigne l'utilisateur des Services WasteX.</p>

              <p><span class="font-semibold">Commande :</span> désigne la commande d'enlèvement de Déchets passée à travers la Plateforme et conclue entre un Recycleur de Déchets et un producteur de Déchets.</p>

              <p><span class="font-semibold">Contrat :</span> désigne les présentes CGU.</p>

              <p><span class="font-semibold">Date de la mise à disposition de la Plateforme :</span> désigne la date à laquelle le Prestataire mettra la Plateforme à disposition du Client en lui fournissant des identifiants d'accès à la Plateforme.</p>

              <!-- Continue with all definitions... -->
            </div>
          </section>

          <!-- Article 2 -->
          <section id="article-2" class="p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 2 - CONDITIONS DE MISE À DISPOSITION DES SERVICES WASTEX
            </h2>

            <div class="space-y-6">
              <section>
                <h3 class="text-xl font-semibold mb-3">
                  2.1 Les Services WasteX
                </h3>
                <div class="pl-4 space-y-2">
                  <p>Le Prestataire met à disposition du Client, la Plateforme accessible sur son serveur par le biais du réseau Internet.</p>
                  <p>Le Prestataire propose plusieurs services et fonctionnalités associées à ladite Plateforme.</p>
                </div>
              </section>

              <section>
                <h3 class="text-xl font-semibold mb-3">
                  2.2 Paramétrage et déploiement de la Plateforme
                </h3>
                <div class="pl-4 space-y-2">
                  <p>Le Prestataire s'engage à réaliser sur la Plateforme dont il est propriétaire les opérations techniques permettant au Client d'y avoir accès.</p>
                  <p>En cas de besoin, l'effectivité des opérations de paramétrage sera prouvée par un écrit établissant la mise en service. En l'absence d'écrit précité et de réclamation du Client à la suite de sa première utilisation de la Plateforme, il sera considéré par les Parties que les opérations de paramétrages ont été satisfaites.</p>
                </div>
              </section>

              <section>
                <h3 class="text-xl font-semibold mb-3">
                  2.3 Formation sur l'utilisation de la Plateforme
                </h3>
                <div class="pl-4 space-y-2">
                  <p>Le Prestataire assure des prestations de formation en distanciel garantissant la prise en main autonome de la Plateforme par le Client, permettant à ce dernier de former à son tour ses Utilisateurs.</p>
                  <p>Pour toute formation en présentiel, un devis complémentaire sera soumis au Client.</p>
                </div>
              </section>

              <section>
                <h3 class="text-xl font-semibold mb-3">
                  2.4 Hébergement des Données Client
                </h3>
                <div class="pl-4 space-y-2">
                  <p>Le Prestataire s'engage à héberger les Données Client sur des serveurs permettant leur traitement distant et leur sauvegarde. Les Données Client sont hébergées dans des serveurs situés dans l'Union européenne.</p>
                  <p>Le Prestataire s'engage à prendre des mesures adaptées à la spécificité des Données Client et au risque pour :</p>
                  <ul class="list-disc pl-6 space-y-2">
                    <li>assurer la sécurité informatique et physique de ses serveurs et la sauvegarde périodique des données incluant les Données Client</li>
                    <li>prévenir les risques de fraude informatique</li>
                    <li>permettre la traçabilité et la conservation de l'historique des actions réalisées dans le cadre de l'hébergement</li>
                    <li>mettre en œuvre les moyens techniques et humains nécessaires au respect de ses engagements conformément aux règles de l'art</li>
                  </ul>
                </div>
              </section>

              <section>
                <h3 class="text-xl font-semibold mb-3">
                  2.5 Accès à la Plateforme
                </h3>
                <div class="pl-4 space-y-4">
                  <p>Les Services WasteX sont fournis dans le cadre d'une infrastructure sécurisée dans le cloud computing utilisant les ressources du Prestataire et de ses sous-traitants, sous réserve du respect par le Client des obligations lui incombant, notamment en ce qui concerne l'acquittement du prix de la connexion au réseau Internet.</p>

                  <p>Le Client utilisera seul le droit d'accès à la Plateforme transmis par le Prestataire. Le Client pourra se connecter à tout moment, à l'exception des périodes de maintenance, à savoir :</p>
                  <ul class="list-disc pl-6 space-y-1">
                    <li>24 heures sur 24,</li>
                    <li>7 jours sur 7,</li>
                    <li>y compris les dimanche et jours fériés,</li>
                    <li>avec assistance des équipes techniques du Prestataire.</li>
                  </ul>

                  <p class="mt-4">
                    L'accès à la Plateforme s'effectue :
                  </p>
                  <ul class="list-disc pl-6 space-y-1">
                    <li>à partir de tout ordinateurs ou téléphones mobiles Clients</li>
                    <li>au moyen des identifiants fournis au Client</li>
                  </ul>

                  <div class="mt-4">
                    <p>L'identification du Client lors de son accès à la Plateforme se fait au moyen :</p>
                    <ul class="list-disc pl-6 space-y-1">
                      <li>d'un identifiant attribué à chaque Utilisateur par le Prestataire,</li>
                      <li>et d'un mot de passe communiqué au Client par le Prestataire.</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h3 class="text-xl font-semibold mb-3">
                  2.6 Confidentialité des identifiants
                </h3>
                <div class="pl-4 space-y-2">
                  <p>Les identifiants sont personnels et confidentiels. Le Client s'engage à mettre tout en œuvre pour conserver secrets les identifiants le concernant et à ne pas les divulguer sous quelque forme que ce soit.</p>
                  <p>Le Client est entièrement responsable de l'utilisation des identifiants et il est responsable de la conservation et sécurité des codes d'accès qui lui sont remis. Il s'assurera qu'aucune autre personne non autorisée par le Prestataire n'a accès à la Plateforme.</p>
                </div>
              </section>

              <section>
                <h3 class="text-xl font-semibold mb-3">
                  2.7 Réseau internet
                </h3>
                <div class="pl-4 space-y-2">
                  <p>Le Client est averti des aléas techniques inhérents à Internet, et des interruptions d'accès qui peuvent en résulter. En conséquence, le Prestataire ne sera pas tenu responsable des éventuelles indisponibilités ou ralentissements de la Plateforme.</p>
                  <p>Le Prestataire n'est pas en mesure de garantir la continuité d'accès à la Plateforme, exécutée à distance via Internet, ce que le Client reconnaît.</p>
                </div>
              </section>
              <!-- Continue with all sections of Article 2... -->
            </div>
          </section>

          <!-- Article 3 -->
          <section id="article-3" class="p-6 rounded-lg shadow-sm mt-8">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 3 – LA PASSATION DE COMMANDE ET SON EXÉCUTION
            </h2>
            <div class="space-y-4">
              <p>Par son accès à la Plateforme, le Client a la possibilité de passer une Commande d'enlèvement de Déchets présents sur son Site ou ses Sites conformément aux renseignements indiqués au sein de la Proposition commerciale et au sein de la Plateforme. Afin d'être effective, cette Commande devra être validée par un Recycleur de Déchets.</p>
              <p>Cette Commande d'enlèvement de Déchets par le Client et son acceptation par un Recycleur de Déchets correspond à un contrat entre cette entreprise et le Client, auquel le Prestataire n'est pas partie.</p>
              <p>Le Client reconnaît que le Prestataire n'a aucune obligation dans cette prestation d'enlèvement de Déchets et que son rôle se limite à celui d'intermédiaire technique donnant accès à la Plateforme. Il est ainsi entendu entre les Parties que la responsabilité du Prestataire ne pourra aucunement être recherchée pour tout fait survenu pendant l'exécution de la prestation d'enlèvement de Déchets.</p>
            </div>
          </section>

          <!-- Article 4 -->
          <section id="article-4" class="p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 4 - MAINTENANCE ET ÉVOLUTION DE LA PLATEFORME
            </h2>

            <section>
              <h3 class="text-xl font-semibold mb-3">
                4.1 Assistance technique
              </h3>
              <div class="pl-4">
                <p>Le Prestataire assure une hotline téléphonique à destination du Client, 5 jours sur 7 (jours ouvrés) de 9 heures à 18 heures.</p>
              </div>
            </section>

            <section class="mt-6">
              <h3 class="text-xl font-semibold mb-3">
                4.2 Maintenance corrective
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Client s'engage à déclarer au Prestataire toute Anomalie constatée dans les plus brefs délais via l'outil de ticketing dédié ou par tout autre moyen indiqué par le Prestataire. De même, le Client s'engage également à décrire avec le plus de précision possible la ou les difficultés rencontrées et les circonstances dans lesquelles elle(s) est (sont) intervenue(s). La description de l'Anomalie faite par le Client doit permettre au Prestataire de caractériser l'incident.</p>

                <div class="space-y-2">
                  <p class="font-medium">
                    (a) En cas d'Anomalie bloquante :
                  </p>
                  <p class="pl-4">
                    La prise en compte du signalement intervient sous 24 heures ouvrées. Le Prestataire s'efforce de corriger l'Anomalie bloquante dans les meilleurs délais, et propose une solution de contournement.
                  </p>
                </div>

                <div class="space-y-2">
                  <p class="font-medium">
                    (b) En cas d'Anomalie majeure :
                  </p>
                  <p class="pl-4">
                    La prise en compte du signalement est effectuée dans les 48 heures ouvrables. Le Prestataire s'efforce de corriger l'Anomalie majeure, et propose une solution de contournement pouvant permettre l'utilisation des fonctionnalités en cause dans les 60 jours ouvrés.
                  </p>
                </div>

                <div class="space-y-2">
                  <p class="font-medium">
                    (c) En cas d'Anomalie mineure :
                  </p>
                  <p class="pl-4">
                    La prise en compte du signalement est effectuée dans les meilleurs délais. Le Prestataire propose la correction de l'Anomalie mineure dans une nouvelle version de la Plateforme qui sera livrée dans le cadre de la maintenance évolutive.
                  </p>
                </div>

                <div class="mt-6">
                  <p class="mb-2">
                    Le Prestataire n'est pas responsable de la Maintenance dans les cas suivants :
                  </p>
                  <ul class="list-disc pl-8 space-y-2">
                    <li>refus du Client de collaborer avec le Prestataire dans la résolution des Anomalies et notamment de répondre aux questions et demandes de renseignement</li>
                    <li>utilisation de la Plateforme de manière non conforme à sa destination ou à sa documentation</li>
                    <li>le non-respect par le Client des prérequis techniques imposés par le Prestataire</li>
                    <li>modification non autorisée de la Plateforme par le Client ou par un tiers</li>
                    <li>manquement du Client à ses obligations au titre du Contrat</li>
                    <li>implantation de tous progiciels, logiciels ou systèmes d'exploitation non compatibles avec la Plateforme</li>
                    <li>défaillance des réseaux de communication électronique</li>
                    <li>acte volontaire de dégradation, malveillance, sabotage</li>
                    <li>détérioration due à un cas de force majeure ou à une mauvaise utilisation de la Plateforme</li>
                  </ul>
                </div>
              </div>
            </section>

            <section class="mt-6">
              <h3 class="text-xl font-semibold mb-3">
                4.3 Maintenance évolutive
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Prestataire effectuera des mises à jour et des évolutions fonctionnelles de la Plateforme.</p>

                <p>Ces mises à jour et évolutions fonctionnelles seront accessibles automatiquement par le Client en fonction du choix effectué par le Prestataire et en fonction du type de fonctionnalité choisi initialement par le Client (fonctionnalités de base ou fonctionnalités premium).</p>

                <p>Si, par les choix effectués, le Client n'a pas accès à ces mises à jour et évolutions fonctionnelles, un accord complémentaire entre le Client et le Prestataire pourra intervenir afin d'en faire bénéficier le Client. Cet accord complémentaire déterminera notamment les modalités d'accès à ces mises à jour et évolutions fonctionnelles ainsi que les conditions financières.</p>

                <p>Afin de permettre cette Maintenance évolutive de la Plateforme, la collaboration du Client est nécessaire. Cette collaboration peut se formaliser au travers de retour Client régulier quant à l'expérience utilisateur portant sur les fonctionnalités de la Plateforme.</p>

                <p>Le Client reconnaît cependant que le Prestataire restera titulaire de l'ensemble des droits de propriété intellectuelle sur les évolutions de la Plateforme. Le Client s'engage ainsi à ne revendiquer aucun droit sur les évolutions et sur la Plateforme.</p>
              </div>
            </section>
          </section>

          <!-- ARTICLE 5 -->
          <section id="article-5" class="  p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 5 - OBLIGATIONS DU CLIENT
            </h2>

            <section>
              <h3 class="text-xl font-semibold mb-3">
                5.1 Obligations quant au traitement des Déchets
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Client respectera les conditions d'accès et d'utilisation de la Plateforme mise à disposition par le Prestataire telles que précisées par le présent Contrat.</p>

                <p>Pour l'enlèvement des Déchets, le Client s'engage à respecter les conditions d'enlèvement des Déchets qu'il a indiquées lors du processus de commande, ou qui ont été communiquées ultérieurement par le Recycleur de Déchets.</p>

                <p>A ce titre, le Client respectera la description des Déchets à enlever et convient qu'un déclassement matière pourra être appliqué si les Déchets enlevés ne correspondent pas à ceux prévus.</p>

                <p>Les tarifs de reprise et les pénalités liées au déclassement de matières sont paramétrés au sein de Plateforme pour chaque Client.</p>

                <p>Le Client facilitera les relations avec les Recycleurs de Déchets et fournira au préalable l'ensemble des informations nécessaires au bon déroulé de la configuration du compte, de la mise en relation avec les recycleurs et à la bonne collecte des Déchets, notamment tout Document Administratif qui serait requis par le Prestataire, par les Recycleurs ou par la Réglementation sur les Déchets.</p>
              </div>
            </section>

            <section class="mt-6">
              <h3 class="text-xl font-semibold mb-3">
                5.2 Obligations spécifiques
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Client s'engage à respecter l'ensemble de la Réglementation sur les déchets.</p>

                <p>Le Client reconnaît ainsi que la responsabilité du Prestataire liée au respect des obligations de la Réglementation sur les déchets ne pourra être recherchée, son rôle se limitant à celui d'intermédiaire technique donnant accès à la Plateforme. Le Client garantit le Prestataire contre toute action intentée par un tiers du fait d'une gestion des Déchets non conforme à la Règlementation sur les déchets.</p>
              </div>
            </section>

            <section class="mt-6">
              <h3 class="text-xl font-semibold mb-3">
                5.3 Utilisation de la Plateforme
              </h3>

              <section class="pl-4">
                <h4 class="text-lg font-medium mb-2">
                  5.3.1 Prérequis techniques
                </h4>
                <div class="pl-4 space-y-4">
                  <p>Le Client reconnaît avoir été informé par le Prestataire de l'ensemble des prérequis techniques nécessaires au fonctionnement optimal de la Plateforme (notamment l'utilisation d'un navigateur récent).</p>

                  <p>A ce titre, le Client est responsable de la mise en conformité des postes informatiques des Utilisateurs avec ces prérequis techniques et donc des corrections et prestations complémentaires nécessaires ainsi que de toutes les conséquences éventuelles dues à un défaut de conformité.</p>

                  <p>Le Client est par ailleurs informé du fait que ces prérequis peuvent évoluer, notamment pour des raisons techniques. Si une évolution intervient en cours d'exécution du Contrat, le Client en sera préalablement informé par le Prestataire.</p>
                </div>
              </section>

              <section class="pl-4 mt-4">
                <h4 class="text-lg font-medium mb-2">
                  5.3.2 Réseau internet
                </h4>
                <div class="pl-4">
                  <p>Le Client déclare être informé qu'il devra, pour accéder à la Plateforme, disposer d'un accès à Internet souscrit auprès du fournisseur de son choix, dont le coût sera intégralement à sa charge.</p>
                </div>
              </section>
            </section>
          </section>

          <!-- Article 6 -->
          <section id="article-6" class="  p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 6 - PROPRIETE INTELLECTUELLE
            </h2>

            <section>
              <h3 class="text-xl font-semibold mb-3">
                6.1 Licence d'utilisation de la Plateforme
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Prestataire reste propriétaire exclusif de tous les droits de propriété intellectuelle sur les textes, logiciels, applications, bases de données, modules, images, design, dessins, animations, prototypes, photographies, fichiers audio et vidéo, éléments graphiques, infographies, documentation, support de formation ou promotionnels présents sur la Plateforme.</p>

                <p>Par les présentes, le Prestataire accorde simplement au Client un droit d'utilisation non-cessible, non-transmissible et non exclusif, de la Plateforme conformément à sa destination, pour ses propres besoins, et aux seules fins de l'exécution du Contrat. Le droit d'utilisation de la Plateforme est concédé au Client pour le monde entier et pour la durée du Contrat.</p>

                <div class="space-y-2">
                  <p>Toute autre reproduction, modification, diffusion ou utilisation de tout ou partie, sous quelque forme de la Plateforme et à quelque fin que ce soit, sans l'autorisation expresse et préalable du Prestataire est interdite et serait constitutive d'une contrefaçon, sanctionnée par le Code de la propriété intellectuelle.</p>

                  <p>En outre, le Prestataire se réserve seul le droit de corriger les erreurs qui seraient constatées par le Client. Ce dernier reconnaît ne pas avoir le droit de procéder lui-même ou de faire procéder par un tiers à la maintenance de la Plateforme.</p>

                  <p>Ensuite, le Client n'est pas autorisé à procéder à la reproduction du code source de la Plateforme, à décompiler, à réaliser des opérations de reverse engineering, à créer ou tenter de créer des travaux dérivés ou encore à adapter, de modifier tout ou partie de la Plateforme en tout ou en partie, de l'exporter, ou de la fusionner avec d'autres outils logiciels.</p>

                  <p>De même, le Client s'interdit toute extraction ou réutilisation des bases de données inhérentes à la Plateforme.</p>

                  <p>Enfin, le Client ne pourra utiliser la Plateforme que conformément aux termes de la présente licence d'utilisation.</p>
                </div>
              </div>
            </section>

            <section class="mt-6">
              <h3 class="text-xl font-semibold mb-3">
                6.2 Propriété des Données Client intégrées dans la Plateforme
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Client est seul titulaire des droits sur les Données Client traitées via la Plateforme. Le Client concède, en tant que de besoin, au Prestataire et à ses sous-traitants une licence non exclusive et mondiale et gratuite lui permettant d'héberger, de mettre en cache, de copier, de sauvegarder et d'afficher lesdites Données Client aux seules fins de l'exécution du Contrat.</p>

                <p>La présente licence prendra fin automatiquement au terme de la durée du Contrat, sauf nécessité de poursuivre l'hébergement des Données Client et leur traitement, notamment dans le cadre de la mise en œuvre de la Réversibilité, telle que prévue par les présentes.</p>

                <p>Le Client déclare et garantit qu'il dispose de toutes les autorisations nécessaires à l'exploitation des Données Client via la Plateforme et qu'il peut en concéder librement licence dans les termes susvisés au Prestataire et à ses sous-traitants.</p>

                <p>Le Client déclare et garantit en outre qu'en créant, installant ou téléchargeant les Données Client via la Plateforme, il n'excède aucun droit qui lui aurait éventuellement été concédé sur tout ou partie des Données Client et qu'il ne porte pas atteinte à des droits de tiers.</p>

                <p>Par ailleurs, le Client s'engage à ne charger et traiter via la Plateforme que des informations à caractère professionnel et licites. En particulier, le Client s'interdit et interdit aux Utilisateurs de charger ou de traiter via la Plateforme toute information contraire aux lois, aux règlements ou à l'ordre public et notamment portant atteinte à la protection de la vie privée, de l'image des personnes ou des droits des tiers. En outre, le Prestataire se réserve le droit de supprimer toute donnée ou tout contenu qui constituerait une atteinte manifeste aux droits des tiers ou aux législations applicables ou une menace sérieuse ou une atteinte effective à l'intégrité de la Plateforme.</p>
              </div>
            </section>
          </section>

          <!-- Article 7 -->
          <section id="article-7" class="  p-6 rounded-lg shadow-sm mt-8">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 7 - PROTECTION DES DONNÉES PERSONNELLES
            </h2>

            <section>
              <h3 class="text-xl font-semibold mb-3">
                7.1 Préambule
              </h3>
              <div class="pl-4 space-y-4">
                <p>Les termes Analyse d'impact relative à la protection des données, Données Personnelles, Personne(s) Concernée(s), Protection des données dès la conception, Protection des données par défaut, Responsable de Traitement, Sous-traitant, Traitement, Transferts, Violations de Données, ont le sens visé par la Réglementation sur les Données Personnelles.</p>

                <div class="space-y-2">
                  <p>Pour les Traitements de données personnelles et les opérations de traitements associés à l'utilisation de la Plateforme tel que listé en Annexe, la qualification des Parties est la suivante :</p>
                  <ul class="list-disc pl-8">
                    <li>WasteX agit en qualité de Responsable de traitement dans le cadre du traitement des données personnelles des interlocuteurs des services commerciaux et comptables de ses Clients</li>
                    <li>WasteX intervient en tant que Sous-traitant dans le cadre du traitement des données personnelles des collaborateurs utilisant la Plateforme WasteX</li>
                    <li>Le Client est Responsable de traitement des données personnelles de ses collaborateurs utilisant la Plateforme WasteX</li>
                  </ul>
                </div>

                <p>Chaque Partie s'engage à traiter les Données Personnelles en conformité avec la Réglementation sur les Données Personnelles.</p>
              </div>
            </section>
            <section>
              <h3 class="text-xl font-semibold mb-3">
                7.2 Obligations du Client en sa qualité de Responsable de traitement
              </h3>
              <div class="pl-4 space-y-4">
                <p>WasteX propose une plateforme B2B impliquant la collecte de très peu de Données Personnelles.</p>

                <div class="space-y-2">
                  <p>Le Client est le seul responsable de la détermination des finalités et des moyens relatifs aux traitements réalisés par le Prestataire au cours de l'exécution du Contrat. Par ailleurs, le Client s'engage, en particulier, à :</p>
                  <ul class="list-disc pl-8">
                    <li>Informer les personnes concernées des Traitement de données personnelles effectués par le Prestataire</li>
                    <li>Veiller, au préalable et pendant toute la durée des traitements, au respect de ses obligations prévues par la Réglementation sur les Données Personnelles</li>
                    <li>Documenter par écrit toute instruction concernant la réalisation de traitements par le Prestataire</li>
                    <li>Ne communiquer au Prestataire uniquement les Données Personnelles nécessaires à la bonne exécution des traitements objets du Contrat</li>
                    <li>Communiquer au Prestataire toute demande d'assistance par écrit, étant précisé que ces demandes doivent être documentées et justifiées</li>
                  </ul>
                </div>
              </div>
            </section>

            <section class="mt-6">
              <h3 class="text-xl font-semibold mb-3">
                7.3 Obligations générales du Prestataire en sa qualité de Sous-traitant
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Prestataire s'engage à prendre les mesures nécessaires au respect de ses obligations par lui-même et par son personnel et notamment à :</p>

                <ul class="list-disc pl-8 space-y-2">
                  <li>ne pas traiter, consulter les Données Personnelles à d'autres fins que l'exécution des prestations, qu'elle effectue pour le Client au titre du Contrat</li>
                  <li>ne pas traiter, consulter les Données Personnelles en dehors du cadre des instructions écrites et documentées et des autorisations reçues de la part le Client</li>
                  <li>informer le Client si, selon lui, une instruction constitue une violation de la Réglementation sur les Données Personnelles</li>
                  <li>prendre en compte, s'agissant de ses outils, produits, applications ou services, les principes de Protection des données dès la conception et de Protection des données par défaut</li>
                  <li>veiller à ce que les personnes autorisées à traiter les Données Personnelles s'engagent à respecter la confidentialité des données ou soient soumises à une obligation légale appropriée de confidentialité</li>
                  <li>prendre les mesures techniques et organisationnelles adaptées à la spécificité des Traitements de Données Personnelles et au risque</li>
                  <li>notifier par écrit au Client toute violation de Données Personnelles dans les meilleurs délais après en avoir pris connaissance</li>
                  <li>fournir une assistance raisonnable au Client afin de permettre à ce dernier de répondre à ces obligations issues de la Réglementation sur les Données Personnelles</li>
                  <li>lorsque cette exigence s'impose à lui, à tenir un registre de toutes les catégories d'activités de Traitement effectuées pour le compte du Client</li>
                  <li>s'assurer qu'aucune Donnée Personnelle n'est transférée hors de l'Espace Économique Européen (EEE)</li>
                </ul>
              </div>
            </section>
          </section>

          <section id="article-8" class="p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 8 - RESPONSABILITE
            </h2>

            <section>
              <h3 class="text-xl font-semibold mb-3">
                8.1 Responsabilité du Client
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Client se porte fort du respect du Contrat par les Utilisateurs, à qui il concède un accès à la Plateforme.</p>

                <p>Le Client est seul responsable de l'utilisation et de la confidentialité des identifiants et des mots de passe. Il devra informer sans délai le Prestataire s'il constate une faille de sécurité liée notamment à la communication volontaire ou au détournement d'identifiants et de mots de passe, afin que le Prestataire puisse prendre sans délai toute mesure adaptée en vue de remédier à la faille de sécurité. De manière générale, le Client assume la responsabilité de la sécurité de son système d'information ainsi que des postes individuels et des équipements mobiles utilisés par les Utilisateurs afin d'accéder à la Plateforme.</p>

                <p>Le Client supporte seul toute responsabilité du fait des Données Client, intégrées et traitées via la Plateforme et répond seul de leur conformité à l'ensemble des réglementations applicables. Le Client est notamment seul responsable de la licéité, la qualité, la pertinence et l'innocuité technique des Données Client.</p>

                <p>En outre, le Client est responsable de tout dommage (quel qu'il soit) qui serait subi par le Prestataire et/ou ses sous-traitants et/ou les tiers, en raison des Données Client intégrés et/ou des actions effectuées par le Client et/ou les Utilisateurs via la Plateforme.</p>

                <p>Enfin, le Client est seul responsable du respect de la Réglementation sur la gestion des Déchets et de la bonne exécution de la Commande conclue avec le Recycleur de Déchets à travers la Plateforme.</p>
              </div>
            </section>

            <section class="mt-6">
              <h3 class="text-xl font-semibold mb-3">
                8.2 Responsabilité du Prestataire
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Prestataire est responsable des dommages directs et prévisibles causés par une inexécution partielle ou totale de ses obligations expressément stipulées dans le Contrat, prouvée par le Client.</p>

                <p>En outre, le Prestataire ne pourra en aucun cas être tenu pour responsable des dommages immatériels et/ou indirects dont le Client pourrait se prévaloir qui pourraient survenir du fait ou à l'occasion de l'exécution du Contrat et de ses suites. De ce fait, il ne pourra être tenu d'indemniser notamment les pertes de production, d'exploitation et de profit et, plus généralement, tout préjudice de nature autre que corporelle ou matérielle.</p>

                <p>Le Prestataire n'est en aucun cas responsable des dommages causés par le fait du Client lui-même (y compris en cas de faute ou de négligence), des Utilisateurs, d'un tiers ou par un cas de force majeure. A ce titre, le Prestataire est dégagé de toute responsabilité en cas d'impossibilité d'accès à la Plateforme du fait d'événements échappant à son contrôle.</p>

                <div class="mt-4">
                  <p>Le Client reconnaît expressément que la responsabilité du Prestataire ne pourra en aucune façon être recherchée en cas de :</p>
                  <ul class="list-disc pl-8 mt-2 space-y-2">
                    <li>Préjudice causé à un tiers ou au Recycleur de Déchets des suites de la mauvaise exécution de la Commande du fait du Client</li>
                    <li>Destruction accidentelle des Données Client par le Client ou un tiers ayant accédé à la Plateforme au moyen des identifiants des Utilisateurs</li>
                    <li>Suspension de l'accès à la Plateforme pour des travaux de Maintenance préalablement notifiés</li>
                    <li>Utilisation de la Plateforme par le Client (ou un Utilisateur) de façon non conforme à la documentation d'utilisation ou non expressément autorisée par le Contrat</li>
                    <li>Poursuites judiciaires à l'encontre du Client (ou d'un Utilisateur) du fait d'une utilisation illicite de la Plateforme</li>
                  </ul>
                </div>

                <p class="mt-4">
                  En tout état de cause, la responsabilité du Prestataire en cas de dommages survenus au Client (pour quelque raison que ce soit et quel que soit le fondement juridique invoqué ou retenu, et tous préjudices confondus et cumulés) sera strictement limitée au montant total des Services WasteX facturées au cours des douze (12) derniers mois précédents la survenance desdits dommages.
                </p>
              </div>
            </section>

            <section class="mt-6">
              <h3 class="text-xl font-semibold mb-3">
                8.3 Force majeure
              </h3>
              <div class="pl-4 space-y-4">
                <p>Les Parties ne seront pas tenues pour responsables pour tout retard ou inexécution qui résulterait d'un cas de force majeure tel que défini par l'article 1218 du Code Civil et tel qu'interprété par la jurisprudence des Cours d'appel et de la Cour de cassation. La Partie qui entend invoquer un cas de force majeure devra sans délai en informer l'autre par écrit.</p>

                <p>Si l'événement de force majeure devait perdurer plus de quinze (15) jours, il pourra être mis fin de plein droit au par l'une ou l'autre des Parties, sans indemnité. La résiliation, dans une telle hypothèse, devra être notifiée par lettre recommandée avec accusé de réception et prendra effet à la date de réception de ladite lettre. La Partie affectée par l'événement de force majeure fera tous ses efforts afin d'éviter, d'éliminer ou de réduire les causes du retard et reprendre l'exécution de ses obligations dès que l'événement invoqué aura le cas échéant disparu.</p>
              </div>
            </section>
          </section>

          <section id="article-9" class="p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 9 - CONFIDENTIALITE
            </h2>

            <div class="space-y-4">
              <p>Chacune des Parties s'engage à respecter la confidentialité de toute information, connaissance et document, de quelque nature que ce soit (notamment technique, intellectuelle, scientifique, commerciale, stratégique, financière, etc.), que l'autre Partie sera amenée à lui transmettre, pour les besoins du Contrat.</p>

              <div class="mt-6">
                <p class="mb-4">
                  Les Parties s'interdisent de divulguer ces informations aux tiers, sauf :
                </p>
                <ul class="list-disc pl-8 space-y-2">
                  <li>si ces informations sont communiquées par les Parties à leurs dirigeants, salariés, conseils et partenaires pour les besoins de l'exécution du présent Contrat.</li>
                  <li>si cette divulgation est nécessaire à l'introduction de toute procédure visant à obtenir l'exécution des stipulations du Contrat.</li>
                  <li>si cette divulgation est requise par un texte législatif ou règlementaire ou une décision judiciaire ou administrative de nature impérative applicable à l'une ou l'autre des Parties.</li>
                  <li>si les informations sont tombées dans le domaine public ou auraient déjà été obtenues par une Partie sans violation des obligations définies aux présentes.</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="article-10" class="p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              ARTICLE 10 - LOI APPLICABLE ET JURIDICTION COMPETENTE
            </h2>

            <div class="space-y-4">
              <p>Les présentes CGU sont soumises au droit français.</p>

              <p>Tout litige ayant son origine dans le Contrat, son interprétation, son exécution ou sa rupture sera soumis à la compétence exclusive du Tribunal de commerce de Bobigny et ce, y compris, en cas de pluralité de défendeurs ou de demande incidente.</p>
            </div>
          </section>

          <section class="p-6 rounded-lg shadow-sm">
            <h2 class="text-2xl font-bold mb-6">
              Annexe : Description du traitement de données personnelles
            </h2>

            <section>
              <h3 class="text-xl font-semibold mb-3">
                1. Traitement, opérations de traitement, finalités et base légale
              </h3>
              <div class="pl-4 space-y-4">
                <p>Le Contrat, incluant la présente annexe, constitue l'instruction du Client au Prestataire quant aux Traitements décrits ci-dessous.</p>

                <p>L'objet du Traitement est de fournir les prestations souscrites dans le cadre du Contrat.</p>

                <div class="mt-4">
                  <p class="mb-2">
                    Les natures du Traitement (la nature des opérations réalisées dans le cadre des prestations) sont les suivantes :
                  </p>
                  <ul class="list-disc pl-8 space-y-1">
                    <li>la collecte directe et indirecte</li>
                    <li>la consultation</li>
                    <li>l'utilisation</li>
                    <li>le stockage</li>
                    <li>toute autre opération nécessaire à la fourniture des prestations de WasteX au Client et aux Utilisateurs</li>
                  </ul>
                </div>

                <div class="mt-4">
                  <p class="mb-2">
                    Les finalités du Traitement sont les suivantes :
                  </p>
                  <ul class="list-disc pl-8 space-y-2">
                    <li>Fournir et gérer les services associés à la Plateforme (ex. formulaire de contact, accès à l'interface de la plateforme via les identifiants et mots de passe, publication des offres, gestions du compte, utilisation des services, support clients, données partenaires, etc.)</li>
                    <li>Assurer la collecte et le partage d'informations (ex. communication entre les utilisateurs, etc.)</li>
                    <li>Améliorer la qualité des prestations de WasteX et l'expérience utilisateur (ex. analyses de l'utilisation des services, lutte contre la fraude, etc.)</li>
                  </ul>
                </div>
              </div>
            </section>

            <section class="mt-8">
              <h3 class="text-xl font-semibold mb-3">
                2. Personnes concernées par le Traitement de Données Personnelles
              </h3>
              <div class="pl-4">
                <p>Salarié ou collaborateurs du Client utilisateurs de la Plateforme Wastex</p>
              </div>
            </section>

            <section class="mt-8">
              <h3 class="text-xl font-semibold mb-3">
                3. Catégories de Données Personnelles
              </h3>
              <div class="pl-4">
                <p>Nom, prénom, numéro de téléphone, adresse email, poste chez le Client</p>
              </div>
            </section>

            <section class="mt-8">
              <h3 class="text-xl font-semibold mb-3">
                Liste des sous-traitant ultérieurs
              </h3>
              <div class="pl-4">
                <div class="overflow-x-auto">
                  <table class="w-full border-collapse border border-neutral mt-4">
                    <thead>
                      <tr>
                        <th class="border border-neutral px-4 py-2 text-left">
                          #
                        </th>
                        <th class="border border-neutral px-4 py-2 text-left">
                          Activité de traitement sous-traitée
                        </th>
                        <th class="border border-neutral px-4 py-2 text-left">
                          Identité du sous-traitant
                        </th>
                        <th class="border border-neutral px-4 py-2 text-left">
                          Localisation des données
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border border-neutral px-4 py-2">
                          1
                        </td>
                        <td class="border border-neutral px-4 py-2">
                          Hébergement des données d'authentification et applications
                        </td>
                        <td class="border border-neutral px-4 py-2">
                          Google Cloud Platform
                        </td>
                        <td class="border border-neutral px-4 py-2">
                          Espace économique Européen
                        </td>
                      </tr>
                      <tr>
                        <td class="border border-neutral px-4 py-2">
                          2
                        </td>
                        <td class="border border-neutral px-4 py-2">
                          Hébergement des données (BDD)
                        </td>
                        <td class="border border-neutral px-4 py-2">
                          neon.tech
                        </td>
                        <td class="border border-neutral px-4 py-2">
                          Espace économique Européen
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section class="mt-8">
              <h3 class="text-xl font-semibold mb-3">
                4. Mesures de sécurité mise en place par le Prestataire
              </h3>
              <div class="pl-4 space-y-4">
                <p>La sécurité est un aspect primordial pour WasteX, qui manipule des données sensibles liées à la gestion des déchets.</p>

                <p>Nous abordons la question de la sécurité avec la pratique du Privacy-by-design et nos usages sont calqués sur les exigences de la norme ISO-27001. L'entreprise a mis en place de nombreuses mesures pour garantir la confidentialité et l'intégrité des informations.</p>

                <div>
                  <p class="mb-2">
                    Cela inclut (non-exhaustivement) entre autres :
                  </p>
                  <ul class="list-disc pl-8 space-y-2">
                    <li>le chiffrement des communications de bout-en-bout via TLS</li>
                    <li>Le chiffrement-en-place de nos bases de données en utilisant les standards de l'industrie (X-AES-256 pour notre base de données par exemple)</li>
                    <li>le hachage sécurisé des mots de passe (qui est externalisée à notre prestataire Google Cloud Platform)</li>
                    <li>la journalisation des activités</li>
                    <li>la journalisation des accès aux données</li>
                    <li>le contrôle strict des accès aux infrastructures ainsi que des audits de sécurité réguliers</li>
                  </ul>
                </div>
              </div>
            </section>
          </section>
        </main>
      </div>
    </template>
    <template #footer>
      <EButton
        class="btn btn-primary text-primary-content"
        :label="t('global.accept_cgu')"
        @click="acceptCondition"
      />
    </template>
  </WModal>
</template>
