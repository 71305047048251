import type {
  ApolloQueryResult,
  FetchResult,
  OperationVariables,
} from '@apollo/client'
import type {
  DocumentNode,
  GraphQLFormattedError,
  OperationDefinitionNode,
} from 'graphql'
import type { Mutation, Query } from '~/types/graphql-backend-types/gql-types'
import { fileContents } from '~/mockedDataForDemo'
import { getApolloClient } from './apollo-clients'

type MutationResultWithValidation = FetchResult<Mutation> & {
  validationError: string | null | undefined
}

export function useGqlMikro(requiresAuth = true) {
  const apolloClient = getApolloClient(requiresAuth)

  const query = async <TData = Query, TVariables = Record<string, any>>(options: {
    query: DocumentNode
    variables?: TVariables extends OperationVariables ? TVariables : never
    filters?: any
  }): Promise<ApolloQueryResult<TData>> => {
    if (useFakeData.value) {
      const definitionNode = options.query.definitions as OperationDefinitionNode[]
      const queryName = definitionNode[0].name?.value as string
      const isMockedData = fileContents.get(queryName)
      if (isMockedData) {
        // @ts-expect-error mocked data
        return { ...isMockedData }
      }
      else {
        const {
          data,
          errors,
          ...rest
        }: ApolloQueryResult<TData> = await apolloClient.query(options)

        return { data, errors, ...rest }
      }
    }
    else {
      const {
        data,
        errors,
        ...rest
      }: ApolloQueryResult<TData> = await apolloClient.query(options)

      return { data, errors, ...rest }
    }
  }

  const mutate = async (options: { mutation: any, variables?: any }): Promise<MutationResultWithValidation> => {
    const result: FetchResult<Mutation> = await apolloClient.mutate(options)

    const errors = result?.errors as GraphQLError[]
    let validationError: string | null | undefined = null
    if (errors && errors.length) {
      const isValidationError = errors[0].extensions.originalError
      validationError = isValidationError ? errors[0].extensions.originalError?.message[0] : null
    }
    return { ...result, validationError }
  }

  return { query, mutate }
}

interface GraphQLValidationError {
  message: string[]
  error: string
  statusCode: number
}

interface GraphQLError extends GraphQLFormattedError {
  extensions: {
    code: string
    stacktrace: string[]
    originalError?: GraphQLValidationError
  }
}
