import type { OrganizationFiltersGraphql } from '~/types/graphql-backend-types/gql-types'

export const useOrganizationStore = defineStore('organization', () => {
  const selectedOrgId = ref<string>()
  const organizationCount = ref(0)
  const orgLoading = ref<boolean>(false)
  const page = ref<number>(1)

  const filter = reactive<OrganizationFiltersGraphql>({
    search: '',
    organizationTypes: undefined,
    hasCompaniesOnboarding: undefined,
    organizationIds: undefined,
  })

  const offset = computed(() => (page.value - 1) * 10)

  return {
    selectedOrgId,
    orgLoading,
    organizationCount,
    page,
    offset,
    filter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useOrganizationStore, import.meta.hot))
