import type { NavigationGuard } from 'vue-router'
import { getAuth } from 'firebase/auth'

export const authGuard: NavigationGuard = async (to, from, next) => {
  const { isAdmin, user } = storeToRefs(useAuthStore())
  const { getCurrentUser } = useAuthStore()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const query = Object.fromEntries(urlSearchParams.entries())

  try {
    // Wait for Firebase to initialize auth state with exponential backoff
    let attempts = 0
    const maxAttempts = 3
    const baseDelay = 500 // 500ms

    while (!authState.initialized && attempts < maxAttempts) {
      const delay = baseDelay * 2 ** attempts
      await new Promise(resolve => setTimeout(resolve, delay))
      attempts++
    }

    if (!authState.initialized) {
      console.warn('Auth state initialization timeout')
      return next({
        path: '/auth/login',
        query,
      })
    }

    // If user isn't loaded yet, try to fetch it
    if (!user.value && getAuth().currentUser) {
      try {
        await getCurrentUser()
      }
      catch (error) {
        console.error('Failed to fetch current user:', error)
        return next({
          path: '/auth/login',
          query,
        })
      }
    }

    if (to.matched.some(record => record.meta.requiresAdmin)) {
      if (!user.value || !isAdmin.value) {
        return next({
          path: '/auth/login',
          query,
        })
      }
    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!user.value) {
        return next({
          path: '/auth/login',
          query,
        })
      }
    }

    next()
  }
  catch (error) {
    console.error('Auth guard error:', error)
    return next({
      path: '/auth/login',
      query,
    })
  }
}
