import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirebaseConfig } from '~/config'

export const fb = initializeApp(getFirebaseConfig)

// export const fbperf = getPerformance(fb)
export const fb_storage = getStorage(fb)
export const fb_auth = getAuth(fb)
export const on_auth_state_changed = onAuthStateChanged
