import type { User } from 'firebase/auth'
import type { Router } from 'vue-router'
import { onError } from '@apollo/client/link/error'
import { getAuth } from 'firebase/auth'
import { i18n } from '~/modules/i18n'
import { getEnvironment } from '~/utils/env'

const errorTimeouts = new Map()

export function createErrorLink(router: Router) {
  return onError(({ graphQLErrors, networkError }) => {
    const { signupInput } = useAuthStore()
    // Keep track of seen error messages and timeouts

    if (graphQLErrors) {
      graphQLErrors.forEach(({
        message,
        locations,
        path,
        extensions,
      }) => {
        const errorMessage = `GraphQL Error: ${message}, Location: ${locations}, Path: ${path}`
        const m = message
        // Check if we've seen this error message before
        if (!errorTimeouts.has(m)) {
          // If not, log it and set a timeout
          if (getEnvironment() !== 'production') {
            console.warn(errorMessage)
          }

          // Create a timeout to remove this error from our tracking after some time
          // This allows future occurrences of the same error to be logged again
          const timeoutId = setTimeout(() => {
            errorTimeouts.delete(m)
          }, 5000) // 5 seconds timeout - adjust as needed

          // Store in our Map
          errorTimeouts.set(m, timeoutId)
          console.log(errorTimeouts)

          // The unauthorized handling remains unchanged
          if (message === '$t.unauthorized' && extensions && 'token' in extensions) {
            router.push('/auth/select-client-type')
            // User already exists in firebase so process to classic signup
            if (getAuth().currentUser) {
              const {
                email,
                displayName,
                uid,
              } = getAuth().currentUser as User
              signupInput.email = email as string
              signupInput.firstName = displayName as string
              signupInput.userId = uid
            }
            addToast(i18n.global.t('global.creation_account_incomplete'), { type: 'info' })
          }
        }
      })
    }

    if (networkError) {
      const errorMessage = `Network Error: ${networkError.message}`
      addToast(errorMessage, { type: 'error' })
    }
  })
}
