import { gql } from '@apollo/client/core'

export const USERS_NEST_QUERY_LIST = gql`
query Users($pagination: PaginationArgs, $filters: UserFilterGraphql) {
  users(pagination: $pagination, filters: $filters) {
    count
    collection {
      role
      lastName
      id
      firstName
      email
      function
      disabled
      client {
        id
        name
      }
      assignedRoles {
        collection {
          resourceId
          resourceType
          roleId
          role {
            id
            name
            ... on CompanyRole {
              company {
                id
                name
              }
            }
            ... on OrganizationRole {
              organization {
                id
                name
              }
            }
            ... on SiteRole {
              site {
                id
                name
              }
            }
          }
        }
      }
      entitlements {
        companies {
          id
          role
        }
        organisations {
          id
          role
        }
        sites {
          id
          role
        }
      }
    }
  }
}
`

export const TOGGLE_TRACKDECHETS_AUTOSIGN = gql`
  mutation ToggleTrackdechetsAutosign {
    toggleTrackdechetsAutosign {
      autosignEnabledForTrackdechets
      id
      isConnectedToTrackdechets
      name
      sites {
        collection {
          address
          id
          name
          zipCode
        }
      }
      slug
    }
  }
`
