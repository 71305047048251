<script setup lang="ts">
import { VERSION } from '@cinqo/common'
import { RotateCcwIcon } from 'lucide-vue-next'
import { hotReload } from '~/helpers/UtilsHelper'

const { loading = true } = defineProps<{
  loading?: boolean
}>()

const { getCurrentUser } = useAuthStore()
const { resetFilters } = useCollectsStore()
const { user } = storeToRefs(useAuthStore())

const { t } = useI18n()
const router = useRouter()

const path = computed(() => location.pathname)
const userloading = ref<boolean>(false)
const isLoading = computed(() => loading || userloading.value)

async function hotReloadApp() {
  await hotReload()
}

watch(() => authState.state, async () => {
  switch (authState.state) {
    case AuthStatus.SIGN_UP_SUCCESS:
      handleSignupSuccess()
      break
    case AuthStatus.SIGN_IN_SUCCESS:
      handleSigninSuccess()
      break
    case AuthStatus.SIGNED_OUT:
      handleSignout()
      break
  }
}, { immediate: true, deep: true })

async function handleSigninSuccess() {
  // Skip authentication handling for following routes
  if (path.value === '/auth/user-invitation' || path.value.includes('/confirm-collect'))
    return

  if (!loading) {
    userloading.value = true
    await getCurrentUser()
    await redirectToPath()
    userloading.value = false
  }
}

async function handleSignupSuccess() {
  if (!loading) {
    userloading.value = true
    await getCurrentUser()
    // Check if we have a specific onboarding path to redirect to
    if (authState.onboardingRedirectPath) {
      const redirectPath = authState.onboardingRedirectPath
      // Reset the path immediately to prevent duplicate redirects
      authState.onboardingRedirectPath = null
      // Navigate to the specified path
      await router.push(redirectPath)
    }
    else {
      await redirectToPath()
    }
    userloading.value = false
  }
}

async function redirectToPath() {
  const queryParams = new URLSearchParams(window.location.search)
  const queryObject = Object.fromEntries(queryParams.entries())

  const targetPath = path.value !== '/auth/login' ? path.value : '/'

  // Preserve the current query parameters
  await router.push({
    path: targetPath,
    query: queryObject,
  })
}

async function handleSignout() {
  if (resetFilters)
    resetFilters()
  // @ts-expect-error user is of type CurrentUserGraphql
  user.value = undefined
  token.value = ''
  beforeAuthChangedUser.value = undefined
  useFakeData.value = false
  selectedTab.value = 0
  authState.firebaseUserPopulated = false
  authState.provider = 'firebase'
  authState.initialized = false
}
</script>

<template>
  <div v-if="isLoading" class="h-full w-full flex flex-col items-center justify-center">
    <img :src="picto" alt="Cinqo logo" class="h-14 w-14 animate-ping">
    <div class="pt-20 text-xl text-gray font-mono">
      {{ t('global.loading') }} ...
    </div>
    <div class="absolute bottom-20 cursor-pointer align-bottom text-base-content" @click="hotReloadApp">
      <RotateCcwIcon :size="32" />
      <span>{{ VERSION }}</span>
    </div>
  </div>

  <slot v-else />
</template>
