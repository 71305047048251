<script setup lang="ts">
import { VERSION } from '@cinqo/common'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { useHead } from '@vueuse/head'
import { hotReload, versionToNumber } from '~/helpers/UtilsHelper'
import { apiStore } from '~/stores/api'
import { hideCrispChat } from './composables/crisp'
import { cleanupFirebaseAuthObserver, firebaseAuthObserver } from './controllers/authentication'

useHead({
  title: `Cinqo - ${VERSION}`,
  meta: [{ name: 'description', content: `Cinqo - ${VERSION}` }],
})

const route = useRoute()
const router = useRouter()
const { initLaunchDarkly } = useFeatureFlag()
const loading = ref<boolean>(false)
const interval_ctx = ref<any>(null)
const show_modal = ref<boolean>(false)
const checkVersionModal = ref<HTMLDialogElement>()

// Extract URL parameters helper function
function getUrlParams() {
  const url = new URL(window.location.href)
  return {
    token: url.searchParams.get('token'),
    searchParams: new URLSearchParams(window.location.search),
  }
}

// Used to authenticate the user using token as query param
async function handleRouting() {
  let query = route?.query
  const { searchParams } = getUrlParams()

  // Handle redirect flow
  if (!query?.go) {
    query = Object.fromEntries(searchParams.entries())
  }

  if (query?.go) {
    await router.push(`/${query.go}`)
  }
}

async function init() {
  // When app is init, declare firebase auth observer
  await firebaseAuthObserver()
  loading.value = true

  if (typeof window !== 'undefined') {
    interval_ctx.value = setInterval(checkVersion, 1000 * 60 * 10) // ask for new version very 10 min
    checkVersion()
  }

  // Await is necessary here
  await initLaunchDarkly()
  handleRouting()
  hideCrispChat()
  loading.value = false
}

async function hotReloadApp() {
  await hotReload()
}

/* checkVersion */
async function checkVersion() {
  const api = apiStore().getApiClient
  const status = await api.getStatus()
  const frontVersion = versionToNumber(VERSION)
  const serverVersion = versionToNumber(status?.version)
  if (frontVersion < serverVersion) {
    show_modal.value = true
    checkVersionModal.value?.showModal()
  }
}

onMounted(() => {
  init()
  checkVersionModal.value = document.getElementById('check_version_modal') as HTMLDialogElement
})

onBeforeUnmount(() => {
  cleanupFirebaseAuthObserver()
})
</script>

<template>
  <CheckVersionModal
    id="check_version_modal"
    v-model="show_modal"
    @load-latest-version="hotReloadApp"
  />

  <ToastContainer />
  <CGUModal v-if="!loading" id="cgu-modal" />

  <AuthProvider :loading="loading">
    <router-view />
  </AuthProvider>

  <VueQueryDevtools />
</template>
