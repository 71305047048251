// toastService.ts
import { reactive } from 'vue'
import { i18n } from '../modules/i18n'

interface ToastOptions {
  message?: string
  duration?: number
  type?: 'info' | 'success' | 'warning' | 'error'
  icon?: Function
  error?: Error
}

interface Toast {
  id: number
  title: string
  message: string
  type: string
  duration: number
  icon?: Function
  progress: number
}

const toasts: Toast[] = reactive([])

function removeToast(id: number) {
  const index = toasts.findIndex(toast => toast.id === id)
  if (index > -1) {
    toasts.splice(index, 1)
  }
}

// Create the instance with the translation function
export function addToast(title: string, options: ToastOptions = {}) {
  const id = Date.now()
  const duration = options.duration || 5000
  const toast: Toast = reactive({
    id,
    title,
    message: options.message || '',
    type: options.type || 'info',
    duration,
    icon: options.icon,
    progress: 100,
  })

  // Handle error object if provided
  if (options.error) {
    const errorMessage = Array.isArray(options.error) ? options.error[0].message : options.error.message
    if (errorMessage.startsWith('$t.')) {
      const translationKey = errorMessage.substring(3) // Remove the $t. prefix
      const translatedMessage = i18n.global.t(`backend_keys.${translationKey}`)
      // Only use the translation if it exists and is different from the key
      if (translatedMessage !== `backend_keys.${translationKey}`) {
        toast.title = translatedMessage
        toast.message = i18n.global.t('errors.try_again_later')
      }
      else {
        toast.title = i18n.global.t('errors.try_again_later')
      }
    }
    else {
      toast.title = i18n.global.t('errors.try_again_later')
    }
    toast.type = 'error'
  }
  else if (toast.type === 'error') {
    toast.message = toast.message || i18n.global.t('errors.try_again_later')
  }

  toasts.push(toast)

  const startTime = Date.now()
  const updateProgress = () => {
    const elapsedTime = Date.now() - startTime
    const progress = 100 - (elapsedTime / duration) * 100
    if (progress <= 0) {
      removeToast(id)
    }
    else {
      toast.progress = progress
      requestAnimationFrame(updateProgress)
    }
  }
  requestAnimationFrame(updateProgress)
}

export { removeToast, toasts }
