import { useLDClient } from 'launchdarkly-vue-client-sdk'

interface FeatureFlag {
  key: string
  value: boolean
}

type FeatureFlagKeys = 'faq' | 'onboarding' | 'signup' | 'sso' | 'welcomeScreen' | 'newPricing'

// Key corresponds to LaunchDarkly feature flag key
export const featureFlags = reactive<Record<FeatureFlagKeys, FeatureFlag>>({
  faq: { key: 'ff_faq', value: false },
  onboarding: { key: 'ff_onboarding', value: false },
  signup: { key: 'ff_signup', value: false },
  sso: { key: 'ff_enable_sso', value: false },
  welcomeScreen: { key: 'welcome-screen', value: false },
  newPricing: { key: 'new-pricing', value: false },
})

// TODO: values here are only evalueated when the app is mounted, no stream are open so variables arent reactive
export function useFeatureFlag() {
  const ldClient = useLDClient()

  async function initLaunchDarkly() {
    await ldClient.waitForInitialization()

    for (const [key, value] of Object.entries(featureFlags) as [FeatureFlagKeys, FeatureFlag][]) {
      featureFlags[key].value = ldClient.variation(value.key, false)
    }
  }

  return { initLaunchDarkly }
}
