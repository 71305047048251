import type { FetchResult } from '@apollo/client'
import type {
  ClientDocumentGraphql,
  CollectDocumentFilters,
  DeleteStoredDocumentGraphqlInput,
  DocumentContext,
  DocumentMetaSubtypes,
  DocumentType,
  ExtractInvoiceDataInput,
  GenerateSignedUploadUrlInput,
  Mutation,
  Query,
  StoredDocumentFilters,
  StoredDocumentGraphql,
  StoreDocumentReferenceInput,
} from '~/types/graphql-backend-types/gql-types'
import { useGqlMikro } from '~/composables/useGqlMikro'
import {
  COLLECT_CSV_EXTRACT,
  DELETE_STORED_DOCUMENT,
  EXTRACT_INVOICE_DATA,
  GENERATE_SIGNED_URL_FROM_ID,
  GENERATE_WASTE_REGISTER,
  GENERRATE_SIGNED_UPLOAD_URL,
  GET_COLLECT_DOCUMENTS,
  GET_DOCUMENTS,
  STORE_DOCUMENT_REFERENCE,
} from '~/queries/documents'

export interface PersistDocumentInput {
  clientId?: string
  file: File
  context: { type: DocumentContext, id: string, subtype?: DocumentMetaSubtypes, onboardingDocument?: boolean }
  type: DocumentType
}

export const useDocumentStore = defineStore('document', () => {
  const { query, mutate } = useGqlMikro()

  const page = ref<number>(1)
  const offset = computed(() => (page.value - 1) * 10)

  const documents = ref<StoredDocumentGraphql[]>([])
  const documentCount = ref<number>(0)

  async function getClientDocuments(f: StoredDocumentFilters = {}, limit = 10): Promise<Query['documents']> {
    const documentFilters = {
      ...(f.contexts && { contexts: f.contexts }),
      ...(f.onboardingDocument && { onboardingDocument: true }),
      ...(f.subtypes && f.subtypes.length > 0 && { subtypes: f.subtypes }),
      ...(f.search && { search: f.search }),
      ...(f.organizationIds && { organizationIds: f.organizationIds }),
    } as StoredDocumentFilters
    const { data, errors } = await query({
      query: GET_DOCUMENTS,
      variables: {
        pagination: {
          limit,
          offset: offset.value,
        },
        filters: { ...documentFilters },
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    documentCount.value = data?.documents.count
    documents.value = JSON.parse(JSON.stringify(data?.documents.collection)) as StoredDocumentGraphql[]

    return data.documents
  }

  function filterDocumentsByMetaSubtype(subtype: DocumentMetaSubtypes) {
    return documents.value.filter((doc: ClientDocumentGraphql) => doc?.subtype === subtype)
  }

  async function getCollectDocuments(f: CollectDocumentFilters): Promise<Query['collectDocuments']> {
    const { data, errors } = await query({
      query: GET_COLLECT_DOCUMENTS,
      variables: {
        pagination: {
          limit: 10,
          offset: offset.value,
        },
        filters: { ...f },
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    documentCount.value = data?.collectDocuments.count
    documents.value = data.collectDocuments.collection as StoredDocumentGraphql[]

    return data.collectDocuments
  }

  async function uploadDocument(payload: { file: File }) {
    const { file } = payload

    const generateFileUrl: GenerateSignedUploadUrlInput = {
      fileName: file.name,
      contentType: file.type,
    }

    // Generate signed upload url
    const signedUploadUrl = await generateSignedUploadUrl(generateFileUrl)

    // Upload file
    await uploadFileToSignedUrl(file, signedUploadUrl.url)
  }
  async function persistDocument(payload: PersistDocumentInput) {
    const {
      clientId,
      file,
      type,
      context,
    } = payload
    // Persist document reference
    const filePayload: StoreDocumentReferenceInput = {
      clientId,
      type,
      fileName: file.name,
      externalReference: context.type,
      referenceObjectId: context.id,
    }
    if (context.subtype) {
      filePayload.subtype = context.subtype
      filePayload.onboardingDocument = context.onboardingDocument ?? false
    }

    const storeDocumentRef = await storeDocumentReference(filePayload)
    return storeDocumentRef
  }
  async function uploadFileToSignedUrl(file: File, signedUrl: string) {
    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response
    }
    catch (error) {
      console.error('Error uploading file:', error)
      throw error
    }
  }
  async function generateSignedUploadUrl(input: GenerateSignedUploadUrlInput): Promise<Mutation['generateSignedUploadUrl']> {
    const { data, errors } = await mutate({
      mutation: GENERRATE_SIGNED_UPLOAD_URL,
      variables: { input },
    }) as FetchResult<Mutation>

    if (errors && errors.length > 0) {
      throw errors
    }

    return data!.generateSignedUploadUrl
  }

  async function storeDocumentReference(input: StoreDocumentReferenceInput) {
    const { data, errors } = await mutate({
      mutation: STORE_DOCUMENT_REFERENCE,
      variables: { input },
    }) as FetchResult<Mutation>

    if (errors && errors.length > 0) {
      throw errors
    }

    return data?.storeDocumentReference
  }

  async function deleteStoredDocument(f: DeleteStoredDocumentGraphqlInput) {
    const { data, errors } = await mutate({
      mutation: DELETE_STORED_DOCUMENT,
      variables: { input: f },
    }) as FetchResult<Mutation>

    if (errors && errors.length > 0) {
      throw errors
    }

    const documentIndexToDelete = documents.value.findIndex(d => d.id === f.id)
    if (documentIndexToDelete !== -1) {
      documents.value.splice(documentIndexToDelete, 1)
    }

    return data?.deleteStoredDocument
  }

  async function collectCSVExtract(fromDate: any, toDate: any): Promise<any> {
    const csvExtract = await query({
      query: COLLECT_CSV_EXTRACT,
      variables: {
        input: {
          fromDate,
          toDate,
        },
      },
    })
    return csvExtract.data.collectCSVExtract.B64Csv
  }

  async function generateWasteRegister(fromDate: any, toDate: any): Promise<any> {
    const wasteRegister = await query({
      query: GENERATE_WASTE_REGISTER,
      variables: {
        input: {
          fromDate,
          toDate,
        },
      },
    })

    return wasteRegister
  }

  async function generateSignedUploadUrlFromId(documentId: string): Promise<StoredDocumentGraphql> {
    const { data, errors } = await query({
      query: GENERATE_SIGNED_URL_FROM_ID,
      variables: { filters: { id: documentId } },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    return data.documents.collection[0] as StoredDocumentGraphql
  }

  async function extractInvoiceData(input: ExtractInvoiceDataInput): Promise<StoredDocumentGraphql> {
    const { data, errors } = await mutate({
      mutation: EXTRACT_INVOICE_DATA,
      variables: { input },
    })

    if (errors && errors.length > 0) {
      throw errors[0]
    }

    return data!.extractInvoiceData
  }

  return {
    documents,
    documentCount,
    page,
    offset,
    getClientDocuments,
    filterDocumentsByMetaSubtype,
    getCollectDocuments,
    uploadDocument,
    persistDocument,
    deleteStoredDocument,
    collectCSVExtract,
    generateWasteRegister,
    generateSignedUploadUrlFromId,
    extractInvoiceData,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDocumentStore, import.meta.hot))
}
