<script lang="ts" setup>
type ButtonSize = 'xs' | 'sm' | 'md' | 'lg'

const {
  size = 'md',
  icon,
  iconSize = 18,
  loading,
  disabled,
  label,
  btnClass,
} = defineProps<{
  size?: ButtonSize
  icon?: Function
  iconSize?: number
  loading?: boolean
  disabled?: boolean
  label?: string
  btnClass?: string
}>()

const emit = defineEmits(['click'])

const attrs = useAttrs()

const btnSizeMap: Record<string, string> = {
  xs: 'btn-xs',
  sm: 'btn-sm',
  md: 'btn-md',
  lg: 'btn-lg',
}
</script>

<template>
  <button v-bind="attrs" class="btn" :class="[btnClass, btnSizeMap[size]]" :disabled="disabled || loading" @click.stop="emit('click')">
    <div v-if="loading" class="loading loading-spinner loading-sm" />
    <slot name="prefix">
      <component :is="icon" v-if="icon && !loading" :size="iconSize" />
    </slot>
    {{ label }}
    <slot />
  </button>
</template>
