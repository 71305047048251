import axios from 'axios'
import qs from 'qs'

import { getBackEndURL } from '~/config/index'
import { fb_auth } from '~/services/firebase'

export const apiConfig = {
  returnRejectedPromiseOnError: true,
  timeout: 60000,
  baseURL: getBackEndURL,
  paramsSerializer: { encode: (params: any) => qs.stringify(params, { indices: false }) },
}

export default class ApiHelper {
  apiClient: any
  apiClientPython: any
  userTokenId: string
  fb_auth: any

  constructor() {
    this.apiClient = axios.create(apiConfig)
    this.fb_auth = fb_auth
    this.userTokenId = ''
  }

  setUserTokenId(userTokenId: string) {
    this.userTokenId = userTokenId
  }

  async getToken() {
    const user: any = await this.fb_auth?.currentUser
    const token: string = await user?.getIdToken()
    this.setUserTokenId(token)
  }

  getAnonymousHeaders() {
    const h = {
      headers: {
        common: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      },
    }
    return h
  }

  async getHeaders(content_type = 'application/json') {
    await this.getToken()
    const h = {
      headers: {
        common: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Content-Type': content_type,
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.userTokenId}`,
        },
      },
    }
    return h
  }

  async getStatus() {
    const resultAxios: any = await this.apiClient.get('/v1/status', this.getAnonymousHeaders())
    return resultAxios?.data
  }

  async renderPdf(data: any) {
    const resultAxios: any = await this.apiClient.post('/v1/pdf', data, {
      ...(await this.getHeaders()),
      responseType: 'blob',
    })
    return resultAxios?.data
  }

  async sendTrackDechetsCode(code: string) {
    const resultAxios: any = await this.apiClient.post('/integrations/trackdechets/callback', { code }, await this.getHeaders())
    return resultAxios?.data
  }
}
