import type { Router } from 'vue-router'
// apolloClient.ts
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client/core'
import { storeToRefs } from 'pinia'
// Make sure to import
import { getBackEndURL } from '~/config'
import { fragmentRegistry, registerFragment } from '~/queries/fragments'
import { fb_auth } from '~/services/firebase'
import { useAdminTokenStore } from '~/stores/adminToken' // Assuming this is the correct import
import { createErrorLink } from './useError'

// Token management
export const token = useStorage('apollo-token', '')

// Add request queue for token refresh
let tokenRefreshPromise: Promise<string | undefined> | null = null
let lastTokenRefresh: number = 0
const TOKEN_REFRESH_INTERVAL = 5 * 60 * 1000 // 5 minutes

export async function getToken(): Promise<string | undefined> {
  try {
    const now = Date.now()
    const user = fb_auth.currentUser

    if (!user) {
      console.log('No current user found when getting token')
      return undefined
    }

    // If we have a valid token and it's not time to refresh yet, return it
    if (token.value && now - lastTokenRefresh < TOKEN_REFRESH_INTERVAL) {
      return token.value
    }

    // If there's already a token refresh in progress, wait for it
    if (tokenRefreshPromise) {
      return tokenRefreshPromise
    }

    tokenRefreshPromise = (async () => {
      try {
        // Always get a fresh token from Firebase
        const newToken = await user.getIdToken(true)
        if (newToken) {
          // Update the token in localStorage
          token.value = newToken
          lastTokenRefresh = now
        }

        return newToken
      }
      finally {
        tokenRefreshPromise = null
      }
    })()

    return tokenRefreshPromise
  }
  catch (error) {
    console.error('Error getting authentication token:', error)
    return undefined
  }
}

// Create a function to initialize Apollo Client with router
export function createApolloClient(router: Router, isPublic = false) {
  // Create the error link with router access
  const errorLink = createErrorLink(router)

  const httpLink = new HttpLink({ uri: `${getBackEndURL}/graphql` })

  // If this is a public client, we don't need auth middleware
  if (isPublic) {
    registerFragment()
    return new ApolloClient({
      link: from([errorLink, httpLink]),
      cache: new InMemoryCache({ fragments: fragmentRegistry }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-and-network',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: { errorPolicy: 'all' },
      },
    })
  }

  const authMiddlewareLink: any = async (operation: any, forward: any) => {
    try {
      const { overlayedUserToken } = storeToRefs(useAdminTokenStore())

      // If we have an overlayed token, use it directly without checking getToken
      if (overlayedUserToken.value) {
        operation.setContext({ headers: { authorization: `Bearer ${overlayedUserToken.value}` } })
        return forward(operation)
      }

      // Get a fresh token with retry logic
      let retryCount = 0
      const maxRetries = 3
      let lastError: Error | null = null

      while (retryCount < maxRetries) {
        try {
          const freshToken = await getToken()
          if (!freshToken) {
            throw new Error('No token available')
          }
          operation.setContext({ headers: { authorization: `Bearer ${freshToken}` } })
          return forward(operation)
        }
        catch (error) {
          lastError = error as Error
          retryCount++
          if (retryCount < maxRetries) {
            // Exponential backoff
            await new Promise(resolve => setTimeout(resolve, 2 ** retryCount * 1000))
          }
        }
      }

      // If all retries failed, forward with empty token and let error handling work
      console.error('Failed to get token after retries:', lastError)
      operation.setContext({ headers: { authorization: '' } })
      return forward(operation)
    }
    catch (error) {
      console.error('Auth middleware error:', error)
      operation.setContext({ headers: { authorization: '' } })
      return forward(operation)
    }
  }

  registerFragment()

  return new ApolloClient({
    link: from([authMiddlewareLink, errorLink, httpLink]),
    cache: new InMemoryCache({ fragments: fragmentRegistry }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: { errorPolicy: 'all' },
    },
  })
}
