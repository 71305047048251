<script setup lang="ts">
import { XIcon } from 'lucide-vue-next'

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: 'Titre',
  },
  size: {
    type: String as PropType<ModalSize>,
    required: false,
    default: 'md',
  },
  id: {
    default: 'modal',
    required: false,
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  cancelFooter: {
    type: Boolean,
    default: true,
  },
})

const open = defineModel<boolean>()
const { t } = useI18n()

type ModalSize = 'sm' | 'md' | 'lg' | 'xl'

const { id, loading } = toRefs(props)
</script>

<template>
  <Teleport to="body">
    <dialog
      :id="id"
      class="modal"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="modal-box max-w-5xl max-h-[90vh] flex flex-col"
      >
        <div v-if="!loading" class="flex flex-col min-h-0">
          <!-- Header -->
          <div class="flex-none px-2 flex justify-between items-center">
            <h3
              id="modal-title"
              class="text-lg font-medium leading-6 text-base-content"
            >
              <slot name="title">
                {{ title }}
              </slot>
            </h3>
            <form method="dialog">
              <EButton
                class="btn-circle btn-ghost"
                :icon="XIcon"
                @click="open = false"
              />
            </form>
          </div>
          <!-- Scrollable Content -->
          <div class="flex-1 overflow-y-auto p-4 min-h-0">
            <slot />
          </div>
          <!-- Footer -->
          <div class="flex-none px-2 pt-6 bg-base-100">
            <div class="modal-action justify-end">
              <form v-if="cancelFooter" method="dialog">
                <EButton
                  class="btn-ghost"
                  :label="t('global.cancel')"
                  @click="open = false"
                />
              </form>
              <slot name="footer">
                <!-- <button class="btn btn-sm btn-primary" @click="emit('ok')">
                  {{ t("global.validate") }}
                </button> -->
              </slot>
            </div>
          </div>
        </div>
        <Loader v-else />
      </div>
      <form method="dialog" class="modal-backdrop">
        <!-- Dont use EButton component here because this button is used to close the modal when clicking outside of it -->
        <button @click="open = false">
          close
        </button>
      </form>
    </dialog>
  </Teleport>
</template>

<style scoped>
.modal-box {
  overflow: visible !important;
}

.modal-box > .flex-1 {
  position: relative;
  overflow: hidden;
}
</style>
