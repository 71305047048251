import type { User } from 'firebase/auth'
import type { provider } from '~/stores/auth'
import { beforeAuthStateChanged, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { fb_auth } from '~/services/firebase'
import { AuthStatus } from '~/stores/auth'

let unsubBeforeAuth: any = null
let unsubAuthState: any = null
let authStateChangeTimeout: NodeJS.Timeout | null = null

export async function firebaseAuthObserver() {
  // Handle before auth state changes
  unsubBeforeAuth = beforeAuthStateChanged(fb_auth, async (user: User | null) => {
    try {
      if (user) {
        beforeAuthChangedUser.value = undefined

        // Set new user data
        beforeAuthChangedUser.value = user
        const provider = user.providerId as provider
        authState.provider = provider
      }
    }
    catch (error) {
      console.error('Error in beforeAuthStateChanged:', error)
      authState.state = AuthStatus.SIGNED_OUT
    }
  })

  // Handle auth state changes
  unsubAuthState = onAuthStateChanged(fb_auth, async (user: User | null) => {
    try {
      authState.initialized = true

      if (user && ![AuthStatus.SSO_SIGN_UP, AuthStatus.SIGN_UP, AuthStatus.SIGN_UP_SUCCESS, AuthStatus.SIGN_IN_SUCCESS].includes(authState.state)) {
        // Clear previous user data
        const { user: currentUser } = storeToRefs(useAuthStore())
        // @ts-expect-error user is of type CurrentUserGraphql
        currentUser.value = undefined
        authState.state = AuthStatus.SIGN_IN_SUCCESS
      }
      else if (!user) {
        authState.state = AuthStatus.SIGNED_OUT
      }
    }
    catch (error) {
      console.error('Error in onAuthStateChanged:', error)
      authState.state = AuthStatus.SIGNED_OUT
    }
  })

  return () => {
    unsubBeforeAuth && unsubBeforeAuth()
    unsubAuthState && unsubAuthState()
  }
}

// Separate cleanup function
export function cleanupFirebaseAuthObserver() {
  if (authStateChangeTimeout) {
    clearTimeout(authStateChangeTimeout)
  }
  if (unsubBeforeAuth) {
    unsubBeforeAuth()
    unsubBeforeAuth = null
  }
  if (unsubAuthState) {
    unsubAuthState()
    unsubAuthState = null
  }
}

export async function signIn(email: string, password: string) {
  try {
    authState.state = AuthStatus.SIGN_IN
    await signInWithEmailAndPassword(fb_auth, email, password)
    authState.state = AuthStatus.SIGN_IN_SUCCESS
  }
  catch (error) {
    console.error('Sign in error:', error)
    throw error
  }
}

export async function logout() {
  try {
    // First cleanup Firebase auth observers to prevent race conditions
    cleanupFirebaseAuthObserver()
    authState.state = AuthStatus.SIGNED_OUT
    // Sign out from Firebase
    await fb_auth.signOut()
    // Force a small delay to ensure Firebase auth state is fully cleared
    await new Promise(resolve => setTimeout(resolve, 100))
    // Reinitialize auth observers
    await firebaseAuthObserver()
  }
  catch (error) {
    console.error('Logout error:', error)
    throw error
  }
}
