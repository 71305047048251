import type { ApolloQueryResult } from '@apollo/client'
import type { User } from 'firebase/auth'
import type {
  CurrentUserCrucialFieldsQuery,
  SignUpInputGraphql,
} from '~/types/graphql-backend-types/gql-types'
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  linkWithCredential,
  signInWithCustomToken,
} from 'firebase/auth'
import { cleanupFirebaseAuthObserver, firebaseAuthObserver, logout } from '~/controllers/authentication'
import {
  GET_CURRENT_USER_CRUCIAL_FIELDS,
  GET_OVERLAYED_CLIENT_TOKEN,
  SIGN_UP,
} from '~/queries/auth'
import { fb_auth } from '~/services/firebase'
import { ClientType } from '~/types/graphql-backend-types/gql-types'

export type provider = 'firebase' | 'microsoft.com' | 'google' | 'custom'

export enum AuthStatus {
  SIGNED_OUT = 'SIGNED_OUT',
  SSO_SIGN_UP = 'SSO_SIGN_UP',
  SSO_SIGN_IN = 'SSO_SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  SIGNED_IN = 'SIGNED_IN',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_UP_FAILED = 'SIGN_UP_FAILED',
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',
  USER_DATA_LOADED = 'USER_DATA_LOADED',
  USER_DATA_LOADING = 'USER_DATA_LOADING',
}

interface AuthState {
  provider: provider
  state: AuthStatus
  initialized: boolean
  firebaseUserPopulated: boolean
  onboardingRedirectPath: string | null
}

export const authState: AuthState = reactive({
  provider: 'firebase',
  state: AuthStatus.SIGNED_OUT,
  initialized: false,
  firebaseUserPopulated: false,
  onboardingRedirectPath: null,
})

// This variable is used in case of failed SSO sign up, getAuth().currentUser is null so we need
// the user set in firebase observer to set the EmailProvider
export const beforeAuthChangedUser = ref<User>()

export const useAuthStore = defineStore('auth', () => {
  const { mutate, query } = useGqlMikro()
  const { overlayedUserToken } = storeToRefs(useAdminTokenStore())
  const router = useRouter()

  // Password used for the sign up flow => need the ref to allow EmailAuthProvider
  const tempPassword = ref('')
  const user = ref<CurrentUserCrucialFieldsQuery['me']>() as Ref<CurrentUserCrucialFieldsQuery['me']>

  const isRecycler = computed(() => {
    return user.value?.client?.__typename === 'RecyclerGraphql' || isAdmin.value
  })

  const isProducer = computed(() => {
    return user.value?.client?.__typename === 'ProducerGraphql' || isAdmin.value
  })

  const isAdmin = computed(() => {
    return user.value?.user?.role === 'admin_app'
  })

  const signupInput = reactive<SignUpInputGraphql>({
    userId: '',
    email: '',
    firstName: '',
    lastName: '',
    function: '',
    type: ClientType.Producer,
    siret: '',
    companyName: '',
    phoneNumber: '',
    onboardingNeeds: [],
  })

  async function refreshToken() {
    try {
      if (fb_auth.currentUser) {
        token.value = await fb_auth.currentUser.getIdToken(true)
      }
    }
    catch (error) {
      console.error('Error refreshing token:', error)
      throw error
    }
  }

  async function getCurrentUserWithRetry(maxRetries = 3, baseDelay = 1000) {
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const { data, errors } = await query({
          query: GET_CURRENT_USER_CRUCIAL_FIELDS,
          variables: { input: { dark: isDark.value } },
        }) as ApolloQueryResult<CurrentUserCrucialFieldsQuery>

        if (errors && errors.length > 0) {
          // Retry with new token
          await refreshToken()
          continue
        }

        user.value = JSON.parse(JSON.stringify(data.me))
        return data?.me ?? {}
      }
      catch (error) {
        if (attempt === maxRetries - 1) {
          console.error('Failed to get current user after retries:', error)
          await logout()
          router.push('/auth/login')
          return {}
        }
        // Exponential backoff
        await new Promise(resolve => setTimeout(resolve, baseDelay * 2 ** attempt))
      }
    }
    return {}
  }

  async function getCurrentUser() {
    return getCurrentUserWithRetry()
  }

  async function signUp(input: SignUpInputGraphql, dontRedirect = false): Promise<void> {
    try {
      // Handle Sign Up
      if (authState.state === AuthStatus.SIGN_UP) {
        try {
          const { user } = await createUserWithEmailAndPassword(fb_auth, input.email, input.password as string)
          input.userId = user.uid
        }
        catch (error: any) {
          // User already exists in firebase
          if (error.code === 'auth/email-already-in-use') {
            authState.state = AuthStatus.SSO_SIGN_UP
            console.warn('User already exists in firebase, proceed to classic sign-up')
          }
          else {
            console.error('Error creating user:', error)
            authState.state = AuthStatus.SIGN_UP_FAILED
            addToast('', { type: 'error' })
            return
          }
        }
      }

      const { errors } = await mutate({
        mutation: SIGN_UP,
        variables: { input },
      })

      if (errors && errors.length > 0) {
        authState.state = AuthStatus.SIGN_UP_FAILED
        throw errors[0]
      }

      // Handle SSO Sign Up - only attempt linking if we're in SSO_SIGN_UP state
      if (authState.state === AuthStatus.SSO_SIGN_UP && tempPassword.value) {
        await linkEmailAuthProviderToUser(signupInput.email, tempPassword.value)
      }

      tempPassword.value = ''
      authState.state = AuthStatus.SIGN_UP_SUCCESS
      if (!dontRedirect)
        router.push('/')
      reset()
    }
    catch (err) {
      authState.onboardingRedirectPath = null
      authState.state = AuthStatus.SIGN_UP_FAILED
      console.error('Error in signUp:', err)
      throw err
    }
  }

  async function linkEmailAuthProviderToUser(email: string, password: string) {
    const user = getAuth().currentUser ?? beforeAuthChangedUser.value

    if (!user) {
      throw new Error('No user available for linking email auth provider')
    }

    const credential = EmailAuthProvider.credential(
      email,
      password,
    )

    try {
      const userCred = await linkWithCredential(user, credential)
      token.value = await userCred.user.getIdToken()
    }
    catch (error: any) {
      // If provider is already linked, we can silently continue
      if (error.code === 'auth/provider-already-linked') {
        return
      }
      // For other errors, we should log and potentially handle them
      console.error('Error linking email auth provider:', error)
      throw error
    }
  }

  async function stealUserIdentity(userIdToUsurpate: string, setOverlayedToken: boolean): Promise<void> {
    try {
      // Set initial state for identity stealing
      authState.state = AuthStatus.SIGN_IN

      const { data, errors } = await mutate({
        mutation: GET_OVERLAYED_CLIENT_TOKEN,
        variables: { input: { userId: userIdToUsurpate } },
      })

      if (errors && errors.length > 0) {
        authState.state = AuthStatus.SIGN_IN_FAILED
        throw errors
      }

      // First clear the token store and user state - this prevents race conditions
      token.value = ''
      // @ts-expect-error cant assign undefined to CurrentUserGraphql
      user.value = undefined
      // Temporarily pause auth observers to prevent race conditions
      cleanupFirebaseAuthObserver()

      // Sign in with the custom token and wait for it to complete
      const userCredential = await signInWithCustomToken(getAuth(), data!.logAs!.userJWT)
      // Force an immediate token refresh to ensure we have the latest token
      const freshToken = await userCredential.user.getIdToken(true)
      // Store in our token store
      token.value = freshToken

      // Store the admin's token if we're overlaying
      if (setOverlayedToken) {
        const adminToken = await fb_auth.currentUser?.getIdToken(true)
        if (adminToken) {
          overlayedUserToken.value = adminToken
        }
      }
      else {
        overlayedUserToken.value = ''
      }

      // Set success state and start loading user data
      authState.state = AuthStatus.SIGN_IN_SUCCESS

      // Restart auth observers after token is properly set
      await firebaseAuthObserver()
    }
    catch (error) {
      console.error('Error in stealUserIdentity:', error)
      authState.state = AuthStatus.SIGN_IN_FAILED
      // Restart auth observers in case of error
      await firebaseAuthObserver()
      throw error
    }
  }

  function reset() {
    signupInput.email = ''
    signupInput.firstName = ''
    signupInput.lastName = ''
    signupInput.function = ''
    signupInput.type = ClientType.Producer
    signupInput.siret = ''
    signupInput.companyName = ''
    signupInput.phoneNumber = ''
    signupInput.onboardingNeeds = []
  }

  return {
    signUp,
    stealUserIdentity,
    getCurrentUser,
    user,
    isAdmin,
    isProducer,
    isRecycler,
    signupInput,
    tempPassword,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
