import gql from 'graphql-tag'

export const SIGN_UP = gql`
  mutation SignUp($input: SignUpInputGraphql!) {
    signUp(input: $input) {
      id
    }
  }
`

export const GET_OVERLAYED_CLIENT_TOKEN = gql`
  mutation LogAs($input: LogAsInput!) {
    logAs(input: $input) {
      userJWT
    }
  }
`
export const GET_CURRENT_USER_CRUCIAL_FIELDS = gql`
  query CurrentUserCrucialFields(
    $input: ClientCustomDashboardInputGraphql
  ) {
    me {
      organization {
        id
      }
      client {
        __typename
        id
        name
        isConnectedToTrackdechets
        autosignEnabledForTrackdechets
        customDashboard(input: $input)
        hasCollects
      }
      user {
        email
        firstName
        id
        lastName
        role
        acceptedTermsAt
        entitlements {
          companies {
            id
            role
          }
          organisations {
            id
            role
          }
          sites {
            id
            role
          }
        }
        assignedRoles {
          collection {
            resourceId
            resourceType
            roleId
            role {
              id
              name
              ... on CompanyRole {
                company {
                  id
                  name
                }
              }
              ... on OrganizationRole {
                organization {
                  id
                  name
                }
              }
              ... on SiteRole {
                site {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_CURRENT_USER_ONBOARDING = gql`
  query CurrentUserOnboarding($onboardingFilters: CompanyOnboardingInputGraphql) {
    me {
      client {
        ... on ProducerGraphql {
          onboardingDone
          onboardingNeeds
          onboarding(filters: $onboardingFilters) {
            done
            onboardingItems {
              done
              key
              value
            }
          }
        }
      }
    }
  }
`

export const GET_CURRENT_USER_RECYCLERS = gql`
  query CurrentUserRecyclers {
    me {
      client {
        ... on ProducerGraphql {
          recyclers {
            collection {
              isPremiumRecycler
              id
              name
              siret
              address {
                line1
                country
                city
              }
              sites {
                collection {
                  address
                  name
                  id
                  zipCode
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_CURRENT_USER_PRODUCERS = gql`
  query CurrentUserProducers {
    me {
      client {
        ... on RecyclerGraphql {
          isPremiumRecycler
          producers {
            collection {
              id
              name
              sites {
                collection {
                  address
                  name
                  id
                  zipCode
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_CURRENT_USER_TREATMENT_CODES = gql`
  query CurrentUserTreatmentCodes {
    me {
      organization {
        id
        treatmentCodes {
          code
          description
          isRemapped
          ... on CustomTreatmentCodeGraphql {
            customTreatmentKind
          }
          ... on RegularTreatmentCodeGraphql {
            treatmentKind
          }
        }
      }
    }
  }
`

export const GET_CURRENT_USER_COMPANY_LOGO = gql`
  query CurrentUserCompanyLogo {
    me {
      client {
        companyLogo {
          id
          name
          signedUrl
        }
      }
    }
  }
`
