import { createApolloClient } from './graphql-nest'

// Function to create and get Apollo clients
export function getApolloClients() {
  const router = useRouter()

  // Create both authenticated and public Apollo clients
  const authenticatedClient = createApolloClient(router, false)
  const publicClient = createApolloClient(router, true)

  return {
    authenticatedClient,
    publicClient,
  }
}

// Helper function to get the appropriate client based on whether auth is required
export function getApolloClient(requiresAuth = true) {
  const { authenticatedClient, publicClient } = getApolloClients()
  return requiresAuth ? authenticatedClient : publicClient
}
